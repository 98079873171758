import React from 'react';
import { Row, Container, Col, Button } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
function DemoFooter() {
  return (
    <>
      {/* <div className="section border-top border-bottom" style={{ background: '#fefefe' }}>
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-dark text-uppercase">
                <b>Contact Us</b>
              </h1>
              <h3 className="text-400">For all general enquiries contact:  ̰</h3>
            </Col>
          </Row>
          <Row>
            {content.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <Card className="p-3 text-center">
                  <h3 className="text-700 pt-3">{data.title}</h3>
                  <div>
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{' '}
                      <a href={`mailto:${data.link}`}>{data.link}</a>
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
             
          </Row>
        </Container>
      </div> */}
      <footer className="footer pt-5 py-3" style={{ background: '#000' }}>
        <Container>
          <Row className="justify-content-center py-5">
            <Col lg={3} className="py-2 text-center">
              <a href="https://www.khaleejtimes.com/">
                <img
                  src={require(`assets/images/logo/logowhite.png`)}
                  style={{ maxWidth: '300px' }}
                  width="100%"
                  alt="Finnovex"
                />
              </a>
            </Col>
            <Col lg={1} />
            <Col lg={3} className="py-2">
              <h2 className="d-inline text-white border-bottom w-100 pb-2">PRODUCTS</h2>
              <h3 className="mt-4">
                <a href="/perception" className="text-white">
                PERCEPTION SOFTWARE
                </a>
              </h3>
            </Col>
            <Col lg={3} className="py-2">
              <h2 className="d-inline text-white pb-2 border-bottom">APPLICATIONS</h2>
              <h3 className="mt-4">
                <a href="/applications" className="text-white">
                  TRAFFIC ANALYTICS
                </a>
              </h3>
              <h3 className="mt-3">
                <a href="/applications" className="text-white">
                  CROWD MANAGEMENT
                </a>
              </h3>
              <h3 className="mt-3">
                <a href="/applications" className="text-white">
                  PEOPLE COUNTING
                </a>
              </h3>
              <h3 className="mt-3">
                <a href="/applications" className="text-white">
                  VIRTUAL FENCING
                </a>
              </h3>
               
            </Col>
            <Col lg={2} className="text-center ">
              <h3 className="mb-0 text-white">Connect With Us</h3>
              {social.map((s) => (
                <Button
                  className="btn-neutral btn-just-icon m-1 p-1"
                  style={{ color: '#fff', background: 'transparent' }}
                  href={s.link}
                >
                  <i className={`fa fa-${s.name}`} />
                </Button>
              ))}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} className={'text-center text-white mt-2'}>
              <p>Copyright © Beamz Software 2022. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
const content = [{ title: 'Poonam Chawla', link: 'poonam@khaleejtimes.com' }];

export default DemoFooter;

const social = [
  {
    name: 'facebook',
    link: '',
  },
  { name: 'instagram', link: '' },
  {
    name: 'twitter',
    link: '',
  },
  {
    name: 'youtube',
    link: '',
  },
  {
    name: 'whatsapp',
    link: '',
  },
];
