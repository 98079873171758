import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Row, Spinner } from 'reactstrap';

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    fetch('https://us-central1-beamzsoftware-526b2.cloudfunctions.net/api/email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError('Something went wrong please try again');
      });
  };
  let inputs = content;
  if (redirect) {
    return <Redirect to={`/thank-you`} />;
  }

  return (
    // <Card
    //   style={{
    //     backgroundColor: "#1e16c7",
    //   }}
    // >
    <div
      style={{
        paddingTop: '1rem',
        backgroundColor: 'transparent',

        overflow: 'hidden',
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col className="mx-auto" lg={6}>
            <Container className="my-auto text-white text-600 pb-5">
              {type === 'REGISTER' && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-dark my-5">
                  {inputs.map((i) =>
                    i.name !== 'phone' ? (
                      <Col lg={i.grid} className="my-2 text-white" key={i.name}>
                        <label>
                          {i.placeholder}
                          {!i.notrequired ? '*' : ''}
                        </label>
                        <Input
                          placeholder={i.placeholder}
                          type={i.type}
                          name={i.name}
                          onChange={onChangeDetails}
                          required={!i.notrequired}
                        />
                      </Col>
                    ) : (
                      <Col lg={6} className="my-2 text-white">
                        <label>Phone*</label>
                        <PhoneInput
                          country={'ca'}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                          inputClass="w-100"
                          dropdownClass="text-dark"
                          value={details.phone}
                          onChange={(phone) => {
                            setDetails({ ...details, phone });
                          }}
                        />
                      </Col>
                    )
                  )}

                  <Col lg={12}>
                    {loading ? (
                      <span>
                        <Spinner className="mt-3" color="warning" size="sm" />
                      </span>
                    ) : (
                      <Button
                        block
                        className="btn text-white text-700 mt-3"
                        color="white"
                        size="lg"
                        type="submit"
                        disabled={loading}
                        outline
                      >
                        Submit
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
          <Col lg={4} className="mt-5">
            <h2 className="text-main text-white">GENERAL INFO</h2>

            <h2 className="text-white text-300 mt-5">
              Office Address :<br />
              Beamz Application Solutions Ltd
              <br />
              420, Banks Road, Kelowna, BC V1X 643,
              <br />
              Canada
              <br />
              Email: info@beamzsoftware.com
              <br />
              <br />
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? 'success' : 'danger';
  const message = success ? success : error;
  if (!success && !error) {
    return '';
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: '1rem' }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  {
    id: 1,
    name: 'first_name',
    type: 'text',
    placeholder: 'First Name',
    grid: 6,
  },
  { id: 2, name: 'last_name', type: 'text', placeholder: 'Last Name', grid: 6 },
  { id: 2, name: 'phone', type: 'text', placeholder: 'Phone', grid: 6 },
  {
    id: 3,
    name: 'email',
    type: 'text',
    placeholder: 'Email',
    grid: 6,
  },
  {
    id: 8,
    name: 'company',
    type: 'text',
    placeholder: 'Company',
    grid: 6,
  },
  {
    id: 6,
    name: 'message',
    type: 'text',
    placeholder: 'Message',
    grid: 12,
  },
];
