import React from 'react';

import LandingPageHeader from 'components/Headers/LandingPageHeader.js';

import Footer from 'components/Footer.js';
import NavbarDiv from 'components/Navbar';
import PerceptionPage from 'components/PerceptionPage';

function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader text="PERCEPTION SOFTWARE"/>
      <div
        style={{
          backgroundImage: 'url(' + require('assets/images/bg.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
        }}
      >
        <PerceptionPage />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
