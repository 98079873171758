import React from 'react';

import LandingPageHeader from 'components/Headers/LandingPageHeader.js';

import Footer from 'components/Footer.js';
import NavbarDiv from 'components/Navbar';
import TextGrid from 'components/TextGrid';
import Perception from 'components/Perception';
import Applications from 'components/Applications';
import Sensors from 'components/Sensors';

function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader />
      <div
        style={{
          backgroundImage: 'url(' + require('assets/images/bg.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
        }}
      >
        <TextGrid />
        <section id="perception" />
        <Perception />
        <section id="applications" />
        <Applications />
        <section id="sensors" />
        <Sensors />

      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
