import React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';

function TextGrid() {
  let pageHeader = React.createRef();
  const content = [
    { image: 'smartinfra.jpeg', title: 'Smart Infrastructure' },
    { image: 'crowd.jpeg', title: 'Crowd Management' },
    { image: 'people.jpeg', title: 'People Counting' },
    { image: 'traffic.jpeg', title: 'Traffic Analysis' },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-center text-white mb-5">APPLICATIONS</h1>
            </Col>
          </Row>
          <Row>
            {content.map((c) => (
              <Col lg={6} xs={6}>
                <Card>
                  <img
                    src={require(`assets/images/${c.image}`)}
                    width="100%"
                    alt="perception"
                    style={{
                      border: '1px solid #fff',
                      borderRadius: '0'
                    }}
                  />
                </Card>
                <h2 className="text-main text-uppercase text-center text-white mt-3 mb-5">
                  {c.title}
                </h2>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
