import React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';

function ApplicationPage() {
  let pageHeader = React.createRef();
  const content = [
    {
      image: 'smartinfra.jpeg',
      title: 'Smart Infrastructure',
      text: `
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    `,
    },
    {
      image: 'crowd.jpeg',
      title: 'Crowd Management',
      text: `
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    `,
    },
    {
      image: 'people.jpeg',
      title: 'People Counting',
      text: `
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    `,
    },
    {
      image: 'traffic.jpeg',
      title: 'Traffic Analysis',
      text: `
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    `,
    },
  ];
  return (
    <>
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-white text-uppercase">
                Smart Cities – Intelligent Traffic Solutions &amp; Crowd Management
              </h1>

              <h2 className="text-white text-300 mt-5">
                Use Beamz Software to get you the right perception application to measure vehicle
                and pedestrians at any location.
                <br />
                <br />
                Perception software offers computation over the point cloud data without capturing
                the facial images or personality identification, making the solutions complete
                privacy safe and GDPR compliant.
                <br />
                <br />
                Beamz Software is enabled to provide you with complete security perception
                application which can be used for people counting, virtual fencing, perimeter
                protection or intrusion detection use cases. Beamz Software also assists in getting
                the dashboard application with end user meaningful data display being created from
                the information/data processed from perception applications.
                <br />
                <br />
              </h2>
            </Col>
          </Row>
          <Row>
            {content.map((c) => (
              <Col lg={6} key={c.image}>
                <Card>
                  <img
                    src={require(`assets/images/${c.image}`)}
                    width="100%"
                    alt="perception"
                    style={{
                      border: '1px solid #fff',
                      borderRadius: '0',
                    }}
                  />
                </Card>
                <h2 className="text-main text-uppercase text-center text-white mt-3 mb-5">
                  {c.title}
                </h2>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ApplicationPage;
