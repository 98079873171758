import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-white">ABOUT BEAMZ SOFTWARE</h1>

              <h2 className="text-white text-300 mt-5">
                Beamz Software is headquartered in British Columbia, Canada and offers perception
                software solutions. Our aim is to be the top provider of operating system for LIDAR
                industry, for different applications.
                <br />
                <br />
                Today LIDAR sensor is used in numerous applications – like autonomous vehicles,
                robotics, industrial automation, crowd analytics, people counting, security and
                virtual fencing and Beamz Software provides solution for all these varying industry
                needs.
                <br />
                <br />
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
