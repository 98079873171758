/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// styles
import 'assets/css/bootstrap.min.css';
// import "assets/scss/paper-kit.scss";
import 'assets/css/paper-kit.min.css';
import 'assets/demo/demo.css';
import 'assets/css/LineIcons.css';
import HomePage from 'views/Home';
import Perception from 'views/Perception';
import Applications from 'views/Applications';

import Contact from 'views/Contact';
import Thankyou from 'views/Thankyou';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <HomePage {...props} />} />
      <Route path="/applications" exact render={(props) => <Applications {...props} />} />
      <Route path="/perception" exact render={(props) => <Perception {...props} />} />
      <Route path="/contact-us" exact render={(props) => <Contact {...props} />} />
      <Route path="/thank-you" exact render={(props) => <Thankyou {...props} />} />

    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
