import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-center text-white mb-5">PERCEPTION SOFTWARE</h1>
            </Col>
            <Col lg={6}>
              <img src={require('assets/images/Perception.jpeg')} width="100%" alt="perception" 
              style={{
                border: "1px solid #fff"
              }}
              />
            </Col>
            <Col lg={6} className="align-self-center">
              <h2 className="text-white text-300 mt-5">
                Deep learning-based Lidar Perception State-of-the-art real-time object detection
                framework, which can predict object candidates from multiple classes, together with
                their 3D oriented bounding box for classification.
                <br />
                <br />
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
