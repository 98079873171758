import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function PerceptionPage() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: 'transparent',
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="align-self-center">
              <h2 className="text-white text-300 mt-5 text-justify">
                Deep learning-based Lidar Perception State-of-the-art real-time object detection
                framework, which can predict object candidates from multiple classes, together with
                their 3D oriented bounding box for classification. Various scene understanding
                tasks, including segmentation, detection, and recognition of all objects in the
                environment. BEAMZ AI-based visual-odometry algorithms enable computing the motion
                of a vehicle, and human, and any object in real-time.
                <br />
                <br />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <img
                src={require('assets/images/Perception1.jpeg')}
                width="100%"
                alt="perception"
                style={{
                  border: '1px solid #fff',
                }}
              />
            </Col>
            <Col lg={6}>
              <img
                src={require('assets/images/Perception2.jpeg')}
                width="100%"
                alt="perception"
                style={{
                  border: '1px solid #fff',
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PerceptionPage;
