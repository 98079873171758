import React from 'react';

import Footer from 'components/Footer.js';
import NavbarDiv from 'components/Navbar';
import ContactUs from 'components/ContactUs';
import LandingPageHeader from 'components/Headers/LandingPageHeader';
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader text="CONTACT US"/>
      <div
        style={{
          backgroundImage: 'url(' + require('assets/images/bg.jpg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
        }}
      >
        <ContactUs />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
