import React from 'react';
import Navbar from 'components/Navbar.js';

import Footer from 'components/Footer.js';

import { Container, Row, Col } from 'reactstrap';

function ThankYou() {
  return (
    <>
      <Navbar />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                Thank you for your enquiry. A member of our team will soon get in touch with you.
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ThankYou;
