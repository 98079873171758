// reactstrap components
import React, { useEffect } from 'react';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

function LandingPageHeader({ text, location }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  useEffect(() => {
    const common = {
      containment: '#bgndVideo',
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: '4/3',
      addRaster: true,
    };

    const videos = [
      {
        videoURL: 'QzXO8jJvPCI',
        ...common,
      },
    ];

    window.jQuery('#bgndVideo').YTPlaylist(videos, false);
  }, []);

  return (
    <div className="section py-0" style={{ backgroundColor: 'transparent' }}>
      <div
        style={{
          background: '#000',
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: -100,
          marginLeft: 'auto',
          marginRight: 'auto',
          pointerEvents: 'none',
          marginTop: !size && '3.5rem',
        }}
      >
        <div id="bgndVideo" className="section player"></div>
      </div>

      <div
        className="page-header"
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          minHeight: size ? '80vh' : '40vh',
          maxHeight: '999px',
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          zIndex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyCoontent: 'center',
        }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center align-self-center">
            <Col lg={12} className="mt-5 pt-5">
              <h1
                className="text-center text-white text-700"
                style={{
                  fontSize: size ? '3.5rem' : '1.5rem',
                }}
              >
                {text || (
                  <>
                    From Point Cloud to Analytics
                    <br />
                    Converting the Physical World to a Digital World
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LandingPageHeader;
