import React from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top ${scroll ? 'navbar-transparent' : 'bg-dark'}`}
        style={{ minHeight: '40px' }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" target="_blank" className="p-0" tag={Link}>
              <img
                src={require(`assets/images/logo/logowhite.png`)}
                className=""
                width="100%"
                style={{ maxWidth: '200px' }}
                alt="logo"
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames('navbar-toggler text-right navbar-toggler', {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar style={{ zIndex: 1000000 }}>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2 text-white`}
                    style={{
                      backgroundColor: 'transparent',
                    }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: '1.5rem' }} />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i} className="">
                    {window.location.pathname === '/' && link.page === '/' ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-600 ml-2 text-white"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-600 ml-2 text-white"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-600 ml-2 text-white"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom text-600 ml-2 text-white"
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className="navbar-item-custom text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  { name: 'HOME', link: '/', page: undefined, items: undefined, grid: 1 },
  {
    name: 'PERCEPTION SOFTWARE',
    link: 'perception',
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: 'APPLICATIONS',
    link: 'applications',
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: 'SENSORS',
    link: 'sensors',
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: 'CONTACT US',
    link: '/contact-us',
    page: undefined,
    items: undefined,
    grid: 1,
  },
];

const social = [
  // {
  //   name: 'facebook',
  //   link: '',
  // },
  // { name: 'instagram', link: '' },
  // {
  //   name: 'twitter',
  //   link: '',
  // },
  // {
  //   name: 'youtube',
  //   link: 'https://www.youtube.com/c/khaleejtimes',
  // },
  // {
  //   name: 'whatsapp',
  //   link: '',
  // },
];
